import React from "react";
import FooterCartImage from "../../assets/footer-Image.png";
import { Link } from "react-router-dom";
import BankeLogoWhite from "../../assets/BankeMainWhitelogo.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container top-footer">
          <div className="row">
            <div className="footer-subscription">
              <div className="footer-image">
                <img
                  src={FooterCartImage}
                  alt={"Footer's cart"}
                  align="right"
                />
              </div>
              <div className="top-footer-texts">
                <h3 style={{ textAlign: "center" }}>
                  <span style={{ marginRight: "18px" }}>Banke am!</span>
                  <span style={{ color: "#1e90ff" }}>Ko si pressure</span>
                </h3>
                {/* <h3>Subscribe to our Newsletter</h3>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-xl"
                    placeholder="Enter your email address"
                  />
                  <span className="btn btn-primary btn-lg" type="button">
                    Submit
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <div className="footer-bottom-main">
              <hr />
              <div
                className="row"
                style={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  alignItems: "baseline",
                  alignContent: "center",
                }}
              >
                <div className="col-md-4">
                  <Link className="footer-brand" to="/">
                    <img src={BankeLogoWhite} alt={"Banke White Logo"} />
                  </Link>
                  <ul>
                    {/* Temporal Substitute */}
                    <li>3, Anjolaoluwa street, Sango, Ibadan, Oyo, Nigeria.</li>
                    <li>
                      <a href="tel:+2348149930145">+234 814 993 0145</a>
                    </li>
                    <li>
                      <a href="mailto:bankestoreng@gmail.com">
                        bankestoreng@gmail.com
                      </a>
                    </li>
                  </ul>
                  <h5>Follow Us</h5>
                  <ul className="footer-follow-us">
                    {/* Temporal Substitute */}
                    <li>
                      <a
                        href="https://x.com/bankestore"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/bankestore"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i class="fa-brands fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/bankestore">
                        <i class="fa-brands fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h5>COMPANY</h5>
                  <ul>
                    <li>
                      <Link to="/faq">About Us</Link>
                    </li>
                    <li>
                      <Link to="/faq">How Banke works</Link>
                    </li>
                    <li>
                      <Link to="/vendors">Vendors</Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQs</Link>
                    </li>
                    <li>
                      <Link to="/contact">Become a Vendors</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h5>QUICK LINKS</h5>
                  <ul>
                    {/* <li>
                      <Link to="/#">Terms of Services</Link>
                    </li>
                    <li>
                      <Link to="/#">Privacy Policy</Link>
                    </li> */}
                    <li>
                      <Link>Download For Andriod</Link>
                    </li>
                    <li>
                      <Link>Download for IOS</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row footer-copywrite">
              <p>
                For further support, you may visit the Help Center or contact
                our customer service team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
