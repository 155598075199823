import React from "react";
import { useState, useEffect, useContext } from "react";
import http from "../../services/http-commons.js";
import "./Cart.scss";
import { SetAllContext } from "../../services/AllContext.js";
import { toast } from "react-toastify";
import PaystackPop from "@paystack/inline-js";

const CartLists = () => {
  const cities = [
    "Ibadan-Agodi",
    "Ibadan-Ajibode",
    "Ibadan-Apete",
    "Ibadan-Bodija",
    "Ibadan-Dugbe",
    "Ibadan-Idi_Ape-Ashi",
    "Ibadan-Mokola",
    "Ibadan-North",
    "Ibadan-Ojo",
    "Ibadan-Sango",
    "Ibadan-Sango (Poly Ibadan)",
    "Ibadan-UI (University of Ibadan)",
  ];
  const { userID } = useContext(SetAllContext);
  // All useSTATE goes in here
  const [mobile_number, setMobile] = useState(0);
  const [alt_mobile_number, altSetMobile] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [allUsersCarts, setAllUsersCarts] = useState(null);
  const [getDelivery, setGetDelivery] = useState(null);
  const [deliveryAmount, setDeliveryAmount] = useState(null);

  // ================ All useEFFECT() goes in here =================
  useEffect(() => {
    http.CARTHTTP.get(`/all-carts/${userID}`)
      .then((response) => {
        setAllUsersCarts(response.data);
        let total_sum = response.data.reduce(
          (acc, cart) => acc + cart.total_amount,
          0
        );
        if (total_sum >= 5000 && total_sum < 10000) {
          setDeliveryAmount(0);
          // setDeliveryAmount(20000);
        } else if (total_sum >= 10000) {
          setDeliveryAmount(0);
          // setDeliveryAmount(15000);
        } else {
          setDeliveryAmount(0);
          // setDeliveryAmount(40000);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.detail) {
          if (error.response.data.detail === "Could not validate credentials") {
            toast.error(`Please, log in 🤤`, {
              autoClose: 3000,
            });
          } else {
            toast.error(`${error.response.data.detail}`, {
              autoClose: 3000, // 3 seconds
            });
          }
        } else {
          toast.error(`An error occurred `, {
            autoClose: 3000, // 3 seconds
          });
        }
        // console.log("ERRORRRRR", error.response.data.detail);
      });
  }, [userID]);
  // Get deliverys
  useEffect(() => {
    http.DELIVERYHTTP.get(`/get-delivery/${userID}`)
      .then((response) => {
        setGetDelivery(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data.detail) {
          toast.error(`${error.response.data.detail}`, {
            autoClose: 3000, // 3 seconds
          });
        } else {
          toast.error(`Add your delivery location `, {
            autoClose: 3000, // 3 seconds
          });
        }
        // console.log("BBBBBBBERRORRR", error);
      });
  }, [userID]);
  // Trigger Transaction verification

  // All FUNCTIONS goes here
  function decrsQuantity(index) {
    setAllUsersCarts((prevCarts) => {
      return prevCarts.map((cartItem, i) => {
        if (i === index) {
          const newQuantity = cartItem.quantity > 1 ? cartItem.quantity - 1 : 1;
          // Path the quantity
          const updateCart = http.CARTHTTP.patch(
            `/update-cart/${cartItem.id}`,
            {
              quantity: newQuantity,
            }
          );
          toast.promise(
            updateCart,
            {
              pending: "Updating cart...",
              success: "Cart updated! 🎉",
              error: "Unable to update cart 😞",
            },
            {
              autoClose: 3000, // auto close after 3 seconds for each notification
            }
          );
          updateCart
            .then((response) => {
              console.log(response.data);
              return { ...cartItem, quantity: newQuantity };
            })
            .catch((error) => {
              console.error(error);
            });
          window.location.reload();
          return { ...cartItem, quantity: newQuantity };
        }
        return cartItem;
      });
    });
  }

  // Increase Quantity
  function incrsQuantity(index) {
    setAllUsersCarts((prevCarts) => {
      return prevCarts.map((cartItem, i) => {
        if (i === index) {
          const newQuantity = cartItem.quantity + 1;
          // Path the quantity
          const updateCart = http.CARTHTTP.patch(
            `/update-cart/${cartItem.id}`,
            {
              quantity: newQuantity,
            }
          );
          toast.promise(
            updateCart,
            {
              pending: "Updating cart...",
              success: "Cart updated! 🎉",
              error: "Unable to update cart 😞",
            },
            {
              autoClose: 3000, // auto close after 3 seconds for each notification
            }
          );

          updateCart
            .then((response) => {
              // console.log("Quantity updated in DB", response.data);
              window.location.reload();
              return { ...cartItem, quantity: newQuantity }; // Update specific item's quantity
            })
            .catch((error) => {
              console.error("Error updating quantity", error);
            });
          return { ...cartItem, quantity: newQuantity }; // Update specific item's quantity
        }
        return cartItem;
      });
    });
  }
  // Delete Cart Item
  function deleteCartItem(index) {
    setAllUsersCarts((prevCarts) => {
      return prevCarts.map((cartItem, i) => {
        if (i === index) {
          const deleteCart = http.CARTHTTP.delete(
            `/delete-cart/${cartItem.id}`
          );
          toast.promise(
            deleteCart,
            {
              pending: "Deleting cart...",
              success: "Cart deleted! 🎉",
              error: "Unable to delete cart 😞",
            },
            {
              autoClose: 3000, // auto close after 3 seconds for each notification
            }
          );
          deleteCart
            .then((response) => {
              console.log("Cart Deleted", response.data);
              window.location.reload();
            })
            .catch((error) => {
              console.error("Error in Deleting Cart", error);
            });
        }
        return cartItem;
      });
    });
  }
  // Checkout Cart
  const checkoutCart = (reference_id) => {
    if (allUsersCarts) {
      const checkout = http.CARTHTTP.post(
        `/checkout?user_id=${userID}&reference_id=${reference_id}`
      );
      toast.promise(
        checkout,
        {
          pending: "Placing your order...",
          success: "Congratulations! 🎉🥳",
          error: "Unable to place order 😞",
        },
        {
          autoClose: 3000, // auto close after 3 seconds for each notification
        }
      );
      checkout
        .then((response) => {
          console.log(response);
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // );

  const makePayment = async () => {
    if (!getDelivery) {
      toast.info(`Add your delivery location`, {
        autoClose: 3000, // 3 seconds
      });
      return;
    }
    if (allUsersCarts?.length < 1) {
      toast.info(`Add products to your carts`, {
        autoClose: 3000, // 3 seconds
      });
      return;
    }
    try {
      // Initialize payment
      const initialize_payment = await http.PAYMENTHTTP.post(
        `/initialize-transaction/${userID}`
      );
      toast.info(`Please wait`, {
        autoClose: 3000, // 3 seconds
      });
      const accessData = initialize_payment.data.transaction_data.data;
      const userData = initialize_payment.data.user_data;

      // Open Paystack Checkout Modal
      const handler = PaystackPop.setup({
        key: "pk_live_d8028fd1e4a7f4e5b2958e546184fbe556248bad", // LIVE === //"pk_test_a8e56967ff27e47c695cf3eaa127e5b0d1641b28",
        email: userData.email,
        amount: `${userData.amount + deliveryAmount}`,
        currency: "NGN",
        access_code: accessData.access_code,
        callback: (response) => {
          // This runs after a successful transaction
          console.log("Payment successful!", response);
          verifyTransactions(accessData.reference);
        },
        onClose: () => {
          console.log("Payment modal closed");
        },
        onError: (error) => {
          console.log("Error with transaction:", error);
        },
      });

      // Open the Paystack modal
      handler.openIframe();
    } catch (error) {
      console.log("Initialization ERROR", error);
    }
  };

  const verifyTransactions = async (paystackAccess) => {
    if (!paystackAccess) return;
    try {
      const verifyData = await http.PAYMENTHTTP.get(
        `/verify-transaction?reference=${paystackAccess}`
      );
      console.log("verifyData", verifyData);
      setTimeout(() => checkoutCart(paystackAccess), 5000);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  // Add Delivery
  function deliveryInfo(e) {
    e.preventDefault();

    const deliveryData = {
      address: address,
      mobile_number: mobile_number,
      alt_mobile_number: alt_mobile_number,
      city: city,
      state: state,
    };
    const createDeliveryData = {
      user_id: userID, //I'd get this from the login credentials
      address: address,
      mobile_number: mobile_number,
      alt_mobile_number: alt_mobile_number,
      city: city,
      state: state,
    };
    if (getDelivery) {
      const updateDelivery = http.DELIVERYHTTP.patch(
        `/update-delivery/${getDelivery.id}`,
        deliveryData
      );
      toast.promise(
        updateDelivery,
        {
          pending: "Updating delivery...",
          success: "Delivery details updated! 🎉",
          error: "Unable to update delivery details 😞",
        },
        {
          autoClose: 3000, // auto close after 3 seconds for each notification
        }
      );
      updateDelivery
        .then((response) => {
          setGetDelivery(response.data);
        })
        .catch((error) => {
          toast.error(
            `${
              error.response.data.detail
                ? error.response.data.detail
                : "An error occured"
            }`,
            {
              autoClose: 3000, // 3 seconds
            }
          );
        });
    } else {
      const addDelivery = http.DELIVERYHTTP.post(
        `/delivery`,
        createDeliveryData
      );
      toast.promise(
        addDelivery,
        {
          pending: "Creating delivery...",
          success: "Delivery details created! 🎉",
          error: "Unable to create delivery 😞",
        },
        {
          autoClose: 3000, // auto close after 3 seconds for each notification
        }
      );
      addDelivery
        .then((response) => {
          setGetDelivery(response.data);
        })
        .catch((error) => {
          toast.error(
            `${
              error.response.data.detail
                ? error.response.data.detail
                : "An error occured"
            }`,
            {
              autoClose: 3000, // 3 seconds
            }
          );
        });
    }
  }

  // ============= ALL HTML ===============
  return (
    <div className="cart-listing">
      <div className="container">
        {/* SHOW DELIVERY DETAILS */}
        <div className="container-fluid delivery-details">
          {getDelivery ? (
            <div className="row d-flex align-items-center">
              <div className="col-md-10">
                <p className="locationing">Delivery Location</p>
                <p>
                  <i className="fa-solid fa-location-dot"> </i>{" "}
                  {getDelivery.address}, {getDelivery.city}, {getDelivery.state}
                </p>
                <p>
                  <i className="fa-solid fa-phone"></i>{" "}
                  {getDelivery.mobile_number} ||{" "}
                  {getDelivery.alt_mobile_number
                    ? getDelivery.alt_mobile_number
                    : "Please, add your alt number"}
                </p>
              </div>
              {/* CHANGE DELIVERY */}
              <div className="col-md-2 change-delivery">
                <p data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  Change
                </p>
              </div>
            </div>
          ) : (
            <div className="row">
              <p
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                className="cart-add-location"
              >
                Add Delivery Location
              </p>
            </div>
          )}
        </div>
        {/* Show ORDERS */}
        <div className="cart-listing-summary">
          <h3>Cart Summary</h3>{" "}
          <p
            className="d-flex justify-content-end align-items-center"
            style={{ fontSize: "12px" }}
          >
            {/* <i>+ ₦{deliveryAmount / 100} delivery fee</i> */}
            <i>+ FREE delivery fee</i>
          </p>
        </div>
        <div className="cart-listing-content">
          {allUsersCarts ? (
            allUsersCarts.map((cartItems, index) => (
              <div
                key={index}
                className="container-fluid cart-listing-container"
              >
                <div className="row d-flex align-items-center cart-listing-items">
                  <div className="col-md-2 cart-listing-img">
                    <img
                      src={cartItems.product.images} // src={cartItems.product.images}
                      alt={cartItems.product.name}
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                    />
                  </div>
                  <div className="col-md-4 cart-listing-product">
                    <h6>{cartItems.product.name}</h6>
                    <p>Vendor: Banke Grocery Store</p>
                    <p>Quantity: {cartItems.quantity}</p>
                  </div>
                  <div className="col-md-3">
                    <p>
                      Quantity:{""}
                      <span
                        style={{
                          border: "1px solid black",
                          padding: "6px",
                          marginLeft: "6px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          decrsQuantity(index);
                        }}
                      >
                        -
                      </span>
                      <span
                        style={{
                          border: "1px solid black",
                          padding: "6px 8px",
                        }}
                      >
                        {cartItems.quantity}
                      </span>
                      <span
                        style={{
                          border: "1px solid black",
                          padding: "6px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          incrsQuantity(index);
                        }}
                      >
                        +
                      </span>
                    </p>
                  </div>
                  <div className="col-md-2 ">
                    <p>
                      <strong>
                        ₦
                        {cartItems.total_amount
                          ? cartItems.total_amount.toLocaleString()
                          : 0}
                      </strong>
                    </p>
                  </div>
                  <div className="col-md-1">
                    <p
                      className="cartlisting-delete"
                      onClick={() => {
                        deleteCartItem(index);
                      }}
                    >
                      <i className="fa-solid fa-trash"></i>
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Your cart is loading</p>
          )}
          <div style={{ marginTop: "18px" }}>
            <button onClick={makePayment} className="btn btn-primary col-12">
              Make Payment
            </button>
          </div>
          {/* 2 */}
        </div>
        <div>
          <p
            className="d-flex justify-content-start align-items-center"
            style={{ fontSize: "12px" }}
          >
            <i>
              <strong>NB:</strong> For the safety of our delivery team, some
              deliveries will be made available at your nearest busstop.
            </i>
          </p>
        </div>
      </div>
      {/* MODALS */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="container-fluid modal-content">
            <p
              data-bs-dismiss="modal"
              className="d-flex justify-content-end align-items-center"
            >
              <i
                style={{ cursor: "pointer" }}
                className="fa-solid fa-xmark"
              ></i>
            </p>
            <div>
              <h4 style={{ fontSize: "18px" }}>Delivery Information</h4>
            </div>
            <form onSubmit={deliveryInfo}>
              {/* 1st Row */}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="form-control"
                      id="addressName"
                      placeholder="1, Sango road"
                    />
                    <label htmlFor="addressName">Full Address</label>
                  </div>
                </div>
              </div>
              {/* 2nd row */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <select
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      className="form-select"
                      id="State"
                    >
                      <option value="" disabled>
                        Select your state
                      </option>
                      <option value="Oyo State">Oyo State</option>
                    </select>
                    <label htmlFor="State">State</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <select
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="form-select"
                      id="City"
                    >
                      <option value="" disabled>
                        Select your city
                      </option>
                      {cities.map((cityName, index) => (
                        <option key={index} value={cityName}>
                          {cityName}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="City">City</label>
                  </div>
                </div>
              </div>
              {/* 3rd Row */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      value={mobile_number}
                      onChange={(e) => setMobile(e.target.value)}
                      className="form-control"
                      id="Mobile"
                      placeholder="08000000001"
                    />
                    <label htmlFor="Mobile">Mobile Number</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      value={alt_mobile_number}
                      onChange={(e) => altSetMobile(e.target.value)}
                      className="form-control"
                      id="Alt_Mobile"
                      placeholder="08000000001"
                    />
                    <label htmlFor="Alt_Mobile">Alt. Mobile Number</label>
                  </div>
                </div>
              </div>
              <div>
                <button
                  data-bs-dismiss="modal"
                  type="submit"
                  className="btn btn-primary col-12"
                >
                  Enter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartLists;
