import React from "react";
import "./ProductCartDetailsTopBar.scss";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SetAllContext } from "../../services/AllContext.js";

const ProductCartDetailsTopBar = () => {
  const { userName, userID } = useContext(SetAllContext);
  const navigate = useNavigate();

  const location = useLocation();
  let UsersName = userName && userID ? userName.toUpperCase() : null;

  const goBack = () => {
    navigate(-1); // Go back to the previous URL
  };

  return (
    <div className="cart-detail-topbar bg-lights sticky-top">
      <div className="container d-flex align-items-center cart-detail-topbar-container">
        <div
          className="d-flex justify-content-start cart-detail-topbar-Back"
          onClick={goBack}
        >
          <i className="fa-solid fa-chevron-left"></i> BACK
        </div>
        <div className="d-flex mx-auto cart-detail-topbar-title">
          <h3>{location.pathname === "/cart" ? "Cart" : "Details"}</h3>
        </div>
        <div className="d-flex justify-content-end cart-detail-topbar-details">
          <div className="me-3">
            <Link to="/profile" style={{ color: "black" }}>
              <i className="fa-solid fa-user"></i>
            </Link>
          </div>
          {userName ? (
            <div className="me-3 cart-detail-topbar-welcome">
              Hello <strong>{UsersName}</strong>
            </div>
          ) : null}

          <div className="me-3">
            {location.pathname !== "/cart" ? (
              <Link to="/cart" style={{ color: "black" }}>
                <i className="fa-solid fa-cart-shopping"></i>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCartDetailsTopBar;
