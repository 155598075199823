import React, { useRef } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "./contact.scss";

// import MainHeader from "../reusables/main_header";

// import Image from "../../assets/homepageJumbotrone.png";

const ContactUs = () => {
  // const [formData, setFormData] = useState({
  //   contactName: "",
  //   contactEmail: "",
  //   contactMessage: "",
  // });

  // Reference to form data for emailjs
  const formRef = useRef();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_1j4h2kf",
        "template_yp1vd98",
        formRef.current,
        "BBYYc513L1AkmjV2t"
      )
      .then(
        (result) => {
          console.log("Sent", formRef.current);
          toast.success(`Message successfully sent! ${result.text}`, {
            autoClose: 3000, // 3 seconds
          });
          formRef.current.reset();
        },
        (error) => {
          toast.error(`Message failed to send ${error.text}`, {
            autoClose: 3000,
          });
        }
      );
  };

  return (
    <div>
      <div className="contact-first-content">
        <div className="container">
          <p style={{ marginBottom: "40px", textAlign: "center" }}>
            <strong>"We’re Here for You!</strong> Whether you have questions,
            need assistance, or just want to say hello, our team at Banke Store
            is always ready to help. Your satisfaction is our priority, so don’t
            hesitate to reach out—we’re just a message away! 🏌🏼‍♂️
          </p>
          <div className="contact-first-content-highlight">
            <div className="highlight1 d-flex align-items-center">
              <div className="faviconz me-2">
                <i
                  class="fa-regular fa-envelope"
                  style={{ color: "#1E90FF" }}
                ></i>
              </div>
              <div>
                <h6 className="mb-0" style={{ color: "#1E90FF" }}>
                  For further Information and Inquiries
                </h6>
                <p className="mb-0">bankestoreng@gmail.com</p>
              </div>
            </div>
            <div className="highlight2 d-flex align-items-center">
              <div className="faviconz me-2">
                <i
                  class="fa-regular fa-envelope"
                  style={{ color: "#1E90FF" }}
                ></i>
              </div>
              <div>
                <h6 className="mb-0" style={{ color: "#1E90FF" }}>
                  For Media Requests
                </h6>
                <p className="mb-0">IG: @bankestore</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <div className="container contact-form-container">
          <h4 style={{ textAlign: "center" }}>Contact Form</h4>

          {/* Attach ref={formRef} directly to the form */}
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Enter Your Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Your Name"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Enter Your Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="name@example.com"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Enter Your Message
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                placeholder="Your Message"
                rows="3"
                required
              ></textarea>
            </div>

            <div className="mb-3 style-button">
              <button type="submit" className="btn btn-primary col-12">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
