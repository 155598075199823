import axios from "axios";

// const PRODUCT_URL = "http://127.0.0.1:8000/api/v1/product";
// const CART_URL = "http://127.0.0.1:8000/api/v1/cart";
// const DELIVERY_URL = "http://127.0.0.1:8000/api/v1/delivery";
// const USER_URL = "http://127.0.0.1:8000/api/v1/users";
// const ORDERITEMS_URL = "http://127.0.0.1:8000/api/v1/order-item";
// const ORDER_URL = "http://127.0.0.1:8000/api/v1/orders";
// const PAYMENT_URL = "http://127.0.0.1:8000/api/v1/payment";

// ======================= DEV ENVIRONMENT =============================
// const PRODUCT_URL = "https://fastapi-cgc3.onrender.com/api/v1/product";
// const CART_URL = "https://fastapi-cgc3.onrender.com/api/v1/cart";
// const DELIVERY_URL = "https://fastapi-cgc3.onrender.com/api/v1/delivery";
// const USER_URL = "https://fastapi-cgc3.onrender.com/api/v1/users";
// const ORDERITEMS_URL = "https://fastapi-cgc3.onrender.com/api/v1/order-item";
// const ORDER_URL = "https://fastapi-cgc3.onrender.com/api/v1/orders";
// const PAYMENT_URL = "https://fastapi-cgc3.onrender.com/api/v1/payment";

// // ====================== PROD ENVIRONMENT =================================
const PRODUCT_URL = "https://backendprod-b2pc.onrender.com/api/v1/product";
const CART_URL = "https://backendprod-b2pc.onrender.com/api/v1/cart";
const DELIVERY_URL = "https://backendprod-b2pc.onrender.com/api/v1/delivery";
const USER_URL = "https://backendprod-b2pc.onrender.com/api/v1/users";
const ORDERITEMS_URL =
  "https://backendprod-b2pc.onrender.com/api/v1/order-item";
const ORDER_URL = "https://backendprod-b2pc.onrender.com/api/v1/orders";
const PAYMENT_URL = "https://backendprod-b2pc.onrender.com/api/v1/payment";

// Helper function to add authorization headers
// const addAuthHeader = (config) => {
//   const token = localStorage.getItem("authToken");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// };

const PRODUCTHTTP = axios.create({
  baseURL: PRODUCT_URL,
});

PRODUCTHTTP.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

const CARTHTTP = axios.create({
  baseURL: CART_URL,
});
CARTHTTP.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

const DELIVERYHTTP = axios.create({
  baseURL: DELIVERY_URL,
});
DELIVERYHTTP.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

const USERHTTP = axios.create({
  baseURL: USER_URL,
});
USERHTTP.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

const ORDERITEMSHTTP = axios.create({
  baseURL: ORDERITEMS_URL,
});
ORDERITEMSHTTP.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

const ORDERHTTP = axios.create({
  baseURL: ORDER_URL,
});
ORDERHTTP.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

const PAYMENTHTTP = axios.create({
  baseURL: PAYMENT_URL,
});
PAYMENTHTTP.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

// [PRODUCTHTTP, CARTHTTP, DELIVERYHTTP, USERHTTP, ORDERITEMSHTTP].forEach(
//   (instance) => {
//     instance.interceptors.request.use(addAuthHeader, (error) =>
//       Promise.reject(error)
//     );
//   }
// );

const exportAPIs = {
  PRODUCTHTTP,
  CARTHTTP,
  DELIVERYHTTP,
  USERHTTP,
  ORDERITEMSHTTP,
  ORDERHTTP,
  PAYMENTHTTP,
};
export default exportAPIs;
