import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { SetAllContext } from "../../services/AllContext";
import "./TopBar.css";
import BankeLogo from "../../assets/BankeMainLogo.png";

const TopBar = () => {
  const { userID } = useContext(SetAllContext);
  // JS
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const handleSetActive = (link) => {
    setActiveLink(link);
  };
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  // HTML
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-lights sticky-top">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={BankeLogo} alt={"Banke Logo"} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          {/* 1 */}
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/home" ? "active" : null
                } `}
                onClick={() => handleSetActive("/")}
                to="/home"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/vendors" ? "active" : null
                } `}
                onClick={() => handleSetActive("/vendors")}
                to="/vendors"
              >
                Vendors
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/shop" || activeLink === "/" ? "active" : null
                } `}
                onClick={() => handleSetActive("/shop")}
                to="/shop"
              >
                Shop
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/faq" ? "active" : null
                } `}
                onClick={() => handleSetActive("/faq")}
                to="/faq"
              >
                FAQ
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/contact" ? "active" : null
                } `}
                onClick={() => handleSetActive("/contact")}
                to="/contact"
              >
                Contact Us
              </Link>
            </li>
          </ul>
          {/* 2 */}
          <ul className="navbar-nav ms-4">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/profile" ? "active" : null
                } `}
                onClick={() => handleSetActive("/profile")}
                to="/profile"
              >
                <i class="fa-regular fa-user"></i>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/saved" ? "active" : null
                } `}
                onClick={() => handleSetActive("/shop")}
                to="/shop"
              >
                <i class="fa-regular fa-heart"></i>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/cart" ? "active" : null
                } `}
                onClick={() => handleSetActive("/cart")}
                to="/cart"
              >
                <i class="fa-solid fa-cart-shopping"></i>
              </Link>
            </li>
          </ul>
          {/* 3 */}
          <ul className="navbar-nav ms-4 top-bar-button">
            {userID === null ? (
              <Link to="/auth/login">
                <button className="btn btn-primary btn-6 btn-md-6 btn-lg-12">
                  Log in
                </button>
              </Link>
            ) : null}
            <button className="btn btn-outline-primary">Become a Vendor</button>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default TopBar;
