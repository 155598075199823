import React from "react";
import { useEffect, useState, useContext } from "react";
import "./products.scss";
import http from "../../services/http-commons.js";
import { toast } from "react-toastify";
import { SetAllContext } from "../../services/AllContext.js";
import { useNavigate } from "react-router-dom";

const Product = (props) => {
  const navigate = useNavigate();
  // All useSTATES stay here
  const [cartBody, setCartBody] = useState(null);
  const { setProductID, userID } = useContext(SetAllContext);
  // All useEFFECT Stays here
  useEffect(() => {
    if (cartBody) {
      const addCart = http.CARTHTTP.post("/add-cart", cartBody);
      toast.promise(
        addCart,
        {
          pending: "Adding to cart...",
          success: "Product successfully added to cart 🎉",
          error: "Error adding product to cart 😞",
        },
        {
          autoClose: 3000, // auto close after 3 seconds for each notification
        }
      );
      addCart
        .then((response) => {
          console.log("response", response);
        })
        .catch((error) => {
          // if (error.response && error.response.data.detail) {
          //   toast.info(`${error.response.data.detail}`, {
          //     autoClose: 3000, // 3 seconds
          //   });
          // }
          if (
            error.response &&
            error.response.data.detail === "Could not validate credentials"
          ) {
            toast.error(`Please, log in 🤤`, {
              autoClose: 3000,
            });
          }
          console.log("ERROR", error.response.data.detail);
        });
    }
  }, [cartBody]);

  // FUNCTIONS
  const addProductToCart = () => {
    setCartBody({
      user_id: userID, //THIS DATA WILL BE COLLECTED WHEN I FIX AUTHENTICATION
      product_id: props.getProduct.id,
      quantity: 1,
      price_per_item: props.getProduct.amount,
    });
  };
  // =================== This needs some state management to be handled
  const productDetails = () => {
    setProductID(props.getProduct.id);
    navigate("/detail/product-details");
  };

  // ============== HTML RENDER HAPPENS HERE ====================
  const stars = Array(props?.starRating || 0).fill(
    <i
      className="fa-solid fa-star"
      aria-hidden="true"
      style={{ color: "#1e90ff" }}
    ></i>
  );
  return (
    <>
      <div className="card product-card">
        <img
          onClick={productDetails}
          src={props.image}
          alt={props.imageAlt}
          className="img-fluid card-img-top products-image"
          loading="lazy"
        />
        <span className="add-to-fav">
          <i class="fa-regular fa-heart"></i>
        </span>
        {/* <div className="card-header">{props.storeName}</div> */}
        <div className="card-body">
          <div onClick={productDetails}>
            <h6 className="card-title">
              <i class="fa-solid fa-store"></i> {props.storeName}
            </h6>
            <h5 className="card-title">{props.productName}</h5>
            <p className="card-text-discount">
              <strong>
                <em>
                  saving ₦
                  {props.getProduct.disc_amount
                    ? props.getProduct.disc_amount.toLocaleString()
                    : 0}
                  ✔
                </em>
              </strong>
            </p>
            <p className="card-text">
              <strong>
                <em>
                  ₦
                  {props.productAmount
                    ? props.productAmount.toLocaleString()
                    : 1}
                </em>
              </strong>
            </p>
            {/* <p className="card-text">{props.starRating}</p> */}
            <p>
              {stars.map((icon, index) => (
                <span key={index} style={{ margin: "0 5px" }}>
                  {icon}
                </span>
              ))}
            </p>
          </div>
          <button
            type="button"
            className="btn btn-outline-primary col-12"
            onClick={addProductToCart}
          >
            ADD TO CART
          </button>
        </div>
      </div>
    </>
  );
};

export default Product;
