import React from "react";
import { useEffect, useState, useMemo } from "react";
import "./Shop.scss";

import MainHeader from "../reusables/main_header";
import Product from "../reusables/products";
import ImgJumbotron from "../reusables/image_jumbotron";
import Image from "../../assets/homepageJumbotrone.png";
import supermarket from "../../assets/supermarket.jpeg";
import http from "../../services/http-commons.js";

const Shop = () => {
  const [productData, setProductData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [query, setQuery] = useState("");

  // Other functions goes here!!!
  const searchAllProducts = () => {
    console.log("All data query", query);
    if (query.length > 0) {
      http.PRODUCTHTTP.get(`/get-all-products?q=${query}`)
        .then((response) => {
          setProductData(response.data);
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    } else {
      http.PRODUCTHTTP.get("/get-all-products")
        .then((response) => {
          setProductData(response.data);
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    }
  };

  // Handle window resizing to update windowWidth state
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch products data once when component mounts
  useEffect(() => {
    http.PRODUCTHTTP.get("/get-all-products")
      .then((response) => {
        setProductData(response.data);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }, []);

  // Responsive styles based on windowWidth
  const responsiveStyles = useMemo(() => {
    if (windowWidth <= 375) {
      return {
        text1Style: { fontSize: "14px", marginBottom: "0" },
        text2Style: { fontSize: "8px", marginBottom: "22px" },
        btnStyle: { width: "150px" },
      };
    } else if (windowWidth > 768) {
      return {
        text1Style: { fontSize: "28px", marginBottom: "0" },
        text2Style: { fontSize: "14px", marginBottom: "22px" },
        btnStyle: { width: "300px" },
      };
    } else {
      return {
        text1Style: { fontSize: "28px", marginBottom: "0" },
        text2Style: { fontSize: "14px", marginBottom: "22px" },
        btnStyle: { width: "300px" },
      };
    }
  }, [windowWidth]);

  // ==================== HTML OUTPUT STARTS HERE ==================================
  return (
    <div>
      <div className="shop-main-header">
        <div className="container">
          <MainHeader
            header={"Save More ON Banke"}
            text={`Experience the ease of shopping for fresh groceries online with Banke, 
              where quality meets convenience. 
              From farm-fresh produce to pantry staples, 
              enjoy swift delivery and hassle-free pickups that fit seamlessly into your busy schedule. 
              Discover a new way to shop that brings freshness to your doorstep with every order.`}
            image={Image}
            imgAlt={"Groceries Promotional image"}
          />
        </div>
      </div>
      <div className="main-content">
        <div className="container container-content">
          <div className="row">
            <div className="input-group inputHolder">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Search Product"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <span
                className="btn btn-primary btn-lg"
                type="button"
                onClick={searchAllProducts}
              >
                Search
              </span>
            </div>
          </div>
          <div className="row">
            <h4>Products</h4>
            {productData ? (
              productData.map((item, index) => (
                <div key={index} className="col-md-3">
                  <Product
                    image={item.images} //Need to do something about this image guy.. It has to display
                    imageAlt={item.name}
                    storeName={"Banke"} // Will create a Vendor Table in the backend later
                    productName={item.name}
                    productAmount={item.amount}
                    starRating={item.ratings}
                    getProduct={item}
                  />
                </div>
              ))
            ) : (
              <p>Products are loading...</p>
            )}
          </div>
        </div>
      </div>
      <div className="jumbotron-area">
        <div
          className="row"
          style={{
            width: "75%",
            marginLeft: "auto",
            marginRight: "auto",
            height: "45vh",
          }}
        >
          <ImgJumbotron
            text1Style={responsiveStyles.text1Style}
            text2Style={responsiveStyles.text2Style}
            text1={"Can’t Spot Your Fave Product?"}
            text2={
              "Hit us up with your specs, and we’ll add it to our incoming lineup!"
            }
            btnStyle={responsiveStyles.btnStyle}
            backgroundImage={supermarket}
            btnText={"SHOOT YOUR REQUEST"}
          />
        </div>
      </div>
    </div>
  );
};

export default Shop;
