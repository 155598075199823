import React from "react";
import TopBar from "../navigator/TopBar";
import "./policy_style.scss";
import Footer from "../footer/Footer";

const Policies = () => {
  return (
    <>
      <TopBar />
      <div className="container policy-article">
        <header>
          <h3>
            General Terms and Conditions of Use of the Marketplace on Banke
          </h3>
        </header>
        <section>
          <ol>
            <li>
              <h4>Introduction</h4>
              <ul>
                <li>
                  Banke manages an online marketplace, accessible via a website
                  and mobile app, that facilitates the buying and selling of
                  consumer goods and services. This platform is supported by IT
                  logistics and a payment infrastructure to streamline
                  transactions.
                </li>
                <li>
                  These policies as well as terms and conditions apply to all
                  buyers and sellers on the marketplace, regulating your access
                  to and use of the platform and its associated services.
                </li>
                <li>
                  By accessing our marketplace, you fully agree to these terms
                  and conditions. If you do not accept any part of them, you
                  must refrain from using the platform.
                </li>
              </ul>
            </li>
            <li>
              <h4>Account Registration and User Access</h4>
              <ul>
                <li>
                  When registering for an account on our marketplace, you will
                  be required to provide an email address along with a password.
                  By doing so, you agree to:
                </li>
                <ul>
                  <li>Keep your password confidential at all times.</li>
                  <li>
                    Inform us in writing immediately if you suspect or become
                    aware of any unauthorized disclosure of your password.
                  </li>
                  <li>
                    Take full responsibility for all activities conducted
                    through your account due to a failure to maintain password
                    confidentiality, acknowledging that you may be held liable
                    for any resulting losses.
                  </li>
                  <li>
                    You may cancel your account at any time by reaching out to
                    us.
                  </li>
                </ul>
              </ul>
            </li>
            <li>
              <h4>Terms and Conditions of Sale</h4>
              <ul>
                <li>
                  By using the marketplace, you acknowledge and agree that:
                </li>
                <ul>
                  <li>
                    The marketplace serves as a digital platform where sellers
                    can list and sell products while buyers can browse and make
                    purchases.
                  </li>
                  <li>
                    We facilitate binding sales on behalf of sellers; however,
                    unless explicitly stated otherwise, we are not a party to
                    transactions between buyers and sellers.
                  </li>
                  <li>
                    A legally binding contract for the sale of a product is
                    established between the buyer and the seller once the buyer
                    confirms the purchase through the marketplace.
                  </li>
                </ul>
                <li>
                  The contract for the sale and purchase of products shall
                  primarily be governed by the seller’s terms of business,
                  subject to these general terms and conditions. In addition,
                  the following provisions shall apply:
                </li>
                <ul>
                  <li>
                    The listed price of a product will be as displayed in its
                    marketplace listing.
                  </li>
                  <li>
                    The product price must include all applicable taxes and
                    comply with prevailing legal requirements.
                  </li>
                  <li>
                    Any additional costs, such as delivery, packaging, handling,
                    administrative fees, insurance, or other applicable charges,
                    will only be borne by the buyer if explicitly stated in the
                    product listing.
                  </li>
                  <li>
                    All products must meet satisfactory quality standards, be
                    safe and fit for their intended purpose, and conform to the
                    specifications and descriptions provided by the seller in
                    the product listing.
                  </li>
                  <li>
                    For physical products, the seller warrants that they have
                    full ownership and legal rights to sell the products, free
                    from third-party claims, intellectual property disputes, or
                    any legal or financial encumbrances.
                  </li>
                </ul>
              </ul>
            </li>
            <li>
              <h4>Returns and Refunds policies</h4>
              <ul>
                <li>
                  The return process for products purchased on the marketplace
                  shall be handled in accordance with the returns policy
                  outlined on the marketplace, which may be updated
                  periodically. Acceptance of returned products is at our
                  discretion, subject to compliance with the applicable laws of
                  the relevant jurisdiction.
                </li>
                <li>
                  Refunds for returned products shall be processed in line with
                  the marketplace’s refund policy, which may be revised from
                  time to time. We retain the discretion to grant refunds in
                  accordance with applicable laws and may issue refunds for:
                </li>
                <ul>
                  <li>The product’s purchase price.</li>
                  <li>
                    Local and/or international shipping fees, as specified in
                    the refund policy.
                  </li>
                  <li>
                    Refunds may be provided through vouchers, mobile money
                    transfers, bank transfers, or any other method we determine.
                  </li>
                </ul>
                <li>
                  Returned products will be accepted, and refunds processed by
                  us on behalf of the seller. However, services, and perishable
                  food items, refunds shall only be issued in cases of delivery
                  failure. Refunds for any other reason concerning such products
                  will be subject to the seller’s terms and conditions
                </li>
                <li>
                  Any modifications to the returns or refunds policy shall take
                  effect from the date of publication on our website and will
                  apply to all purchases made thereafter.
                </li>
              </ul>
            </li>
            <li>
              <h4>Payments</h4>
              <ul>
                <li>
                  You are required to make all payments as stipulated in these
                  general terms and conditions, in accordance with the Payments
                  Information and Guidelines provided on the marketplace.
                </li>
              </ul>
            </li>
            <li>
              <h4>Use of Website and Mobile Applications</h4>
              <ul>
                <li>
                  For the purposes of this section, the terms "marketplace" and
                  "website" shall be used interchangeably to refer to Banke's
                  websites and mobile applications.
                </li>
                <li>
                  On the Banke's websites and mobile applications, You are
                  permitted to:
                </li>
                <ul>
                  <li>View pages from our website via a web browser.</li>
                  <li>
                    Download pages from our website for caching in a web
                    browser.
                  </li>
                  <li>
                    Print pages from our website for personal, non-commercial
                    use, provided such printing is not excessive or systematic.
                  </li>
                  <li>
                    Use marketplace services via a web browser, subject to these
                    terms.
                  </li>
                </ul>
                <li>
                  Our website may only be used for personal and business
                  purposes related to buying or selling products on the
                  marketplace.
                </li>
                <li>
                  Except where expressly permitted, you must not alter, edit, or
                  modify any material on our website.
                </li>
                <li>
                  Unless you own or have rights to specific materials, you must
                  not:
                </li>
                <ul>
                  <li>
                    Republish material from our website, including on other
                    websites.
                  </li>
                  <li>Sell, rent, or sublicense material from our website.</li>
                  <li>Display material from our website in public.</li>
                  <li>Use website material for commercial purposes.</li>
                  <li>Redistribute content from our website.</li>
                </ul>
                <li>
                  You may share links to product pages and distribute
                  newsletters or promotional materials in print or electronic
                  form.
                </li>
                <li>
                  We reserve the right to restrict access to our website or
                  specific features at our discretion, including during
                  maintenance or updates. You must not attempt to bypass or
                  circumvent access restrictions.
                </li>
                <li>You must not:</li>
                <ul>
                  <li>
                    Use the website in ways that may damage, impair, or
                    compromise its performance or security.
                  </li>
                  <li>
                    Engage in unethical, illegal, fraudulent, or harmful
                    activities on the website.
                  </li>
                  <li>Hack, tamper with, or disrupt website functionality.</li>
                  <li>
                    Probe, scan, or test the website’s vulnerabilities without
                    permission.
                  </li>
                  <li>Circumvent authentication or security systems.</li>
                  <li>
                    Use the website to distribute malicious software (e.g.,
                    spyware, viruses, Trojans, rootkits).
                  </li>
                  <li>
                    Overload website resources (e.g., excessive bandwidth or
                    storage use).
                  </li>
                  <li>
                    Decrypt or intercept communications without authorization.
                  </li>
                  <li>
                    Engage in automated data collection (e.g., scraping, mining)
                    without written consent.
                  </li>
                  <li>
                    Use bots, spiders, or automated tools to access the website
                    (except for search engine indexing).
                  </li>
                  <li>
                    Interact with the website except through public interfaces.
                  </li>
                  <li>Violate directives outlined in the robots.txt file.</li>
                  <li>
                    Use website data for direct marketing (e.g., email, SMS,
                    telemarketing).
                  </li>
                  <li>Interfere with normal website use.</li>
                </ul>
              </ul>
            </li>
            <li>
              <h4>Copyright and Trademarks</h4>
              <ul>
                <li>
                  Banke, along with its licensors, owns all copyrights and
                  intellectual property rights related to the website and its
                  materials.
                </li>
                <li>All such intellectual property rights are reserved.</li>
                <li>
                  Banke's logos and trademarks are proprietary. Unauthorized use
                  may constitute infringement.
                </li>
                <li>
                  Third-party trademarks appearing on our website are the
                  property of their respective owners. We do not endorse or
                  affiliate with them and cannot grant any license to use their
                  trademarks.
                </li>
              </ul>
            </li>
            <li>
              <h4>Data Privacy</h4>
              <ul>
                <li>
                  Buyers agree to the processing of their personal data under
                  Banke's Privacy and Cookie Notice.
                </li>
                <li>
                  Banke processes personal data collected via the marketplace in
                  accordance with its Privacy and Cookie Notice and Privacy
                  Policy.
                </li>
                <li>
                  Sellers are solely responsible for handling buyer data. Banke
                  holds no liability for any misuse of buyer data by sellers.
                </li>
              </ul>
            </li>
            <li>
              <h4>Banke's Role as a Marketplace</h4>
              <ul>
                <li>
                  Banke provides a platform where buyers can purchase products
                  either directly from Banke or from independent third-party
                  sellers.
                </li>
                <li>
                  The responsibility for the products sold on the marketplace
                  lies solely with the respective seller, whether it is Banke or
                  an independent third-party vendor.
                </li>
                <li>
                  If a buyer encounters an issue related to a purchase, they
                  should seek resolution directly from the seller through the
                  procedures outlined in Banke's Dispute Resolution Policy.
                </li>
                <li>
                  The marketplace may offer certain products that are unbranded.
                  Buyers acknowledge and accept responsibility for their
                  purchases
                </li>
                <li>
                  Banke, along with third-party sellers, is committed to
                  ensuring that product details displayed on the marketplace are
                  accurate, complete, and up-to-date. Accordingly:
                </li>
                <ul>
                  <li>
                    Each seller guarantees the accuracy and completeness of the
                    product information they provide.
                  </li>
                  <li>
                    Sellers must ensure that their product listings remain
                    current and reflect the latest information.
                  </li>
                  <li>
                    If a buyer believes that the product details provided by a
                    seller (including Banke) are inaccurate or incomplete, they
                    may file a complaint against the seller through Banke's
                    Dispute Resolution Policy.
                  </li>
                </ul>
                <li>
                  Banke does not guarantee uninterrupted service or flawless
                  operation of the marketplace, especially in situations beyond
                  its control, such as:
                </li>
                <ul>
                  <li>
                    Natural disasters (e.g., earthquakes, floods, droughts)
                  </li>
                  <li>
                    Cybersecurity threats (e.g., hacking, malware, or other
                    attacks)
                  </li>
                  <li>
                    Civil unrest (e.g., war, riots, political instability)
                  </li>
                  <li>Public health crises (e.g., pandemics, epidemics)</li>
                </ul>
                <li>
                  Banke reserves the right to modify or discontinue any part of
                  its marketplace services or to take down the marketplace
                  entirely, at its discretion and without prior notice. Such
                  actions will not entitle users to compensation but will not
                  affect any outstanding orders or commitments.
                </li>
                <li>
                  Banke does not promise specific financial or commercial
                  outcomes from using its marketplace. To the fullest extent
                  allowed by law, all warranties and representations related to
                  Banke's marketplace are disclaimed.
                </li>
              </ul>
            </li>
            <li>
              <h4>Liability Limitations and Exclusions</h4>
              <ul>
                <li>
                  No provision in these terms will limit liability where such
                  limitation is not legally permitted.
                </li>
                <li>
                  Statutory rights and liabilities that cannot be lawfully
                  excluded remain unaffected.
                </li>
                <li>
                  These limitations apply to all liabilities under these terms,
                  whether arising from contract, negligence, or statutory
                  obligations, unless explicitly stated otherwise.
                </li>
                <li>
                  Banke will not be held responsible for any damages or losses
                  resulting from the use of free services.
                </li>
                <li>
                  The total liability of Banke under any contract shall not
                  exceed the amount the user has paid for the relevant service.
                  Each transaction on the marketplace is treated as a separate
                  contract.
                </li>
                <li>Banke is not responsible for:</li>
                <ul>
                  <li>Disruptions or failures of the marketplace</li>
                  <li>Losses caused by events beyond its reasonable control</li>
                  <li>
                    Business-related losses, including lost revenue, profits,
                    opportunities, or goodwill
                  </li>
                  <li>Data corruption or software issues</li>
                  <li>Any indirect, incidental, or consequential damages</li>
                </ul>
                <li>
                  Banke operates as a limited liability entity. Therefore, users
                  agree not to pursue personal claims against Banke's employees
                  or officers. This does not prevent claims against Banke itself
                  for their actions.
                </li>
                <li>
                  Links to external sites within the marketplace do not imply
                  endorsement. Banke has no control over third-party content and
                  accepts no responsibility for any harm resulting from their
                  use.
                </li>
                <li></li>
              </ul>
            </li>
            <li>
              <h4>Violations of These Terms and Conditions</h4>
              <ul>
                <li>
                  When an account is created on our marketplace, it remains
                  active indefinitely, provided the user complies with these
                  terms and conditions.
                </li>
                <li>
                  If you violate these terms—or if we reasonably believe that
                  you have breached any Banke policies, guidelines, or codes—we
                  may take any of the following actions:
                </li>
                <ul>
                  <li>Temporarily restrict your access to the marketplace.</li>
                  <li>Permanently revoke your access.</li>
                  <li>
                    Block marketplace access from any device using your IP
                    address.
                  </li>
                  <li>
                    Notify your internet service provider and request that your
                    access be restricted.
                  </li>
                  <li>Suspend or delete your account.</li>
                  <li>
                    Pursue legal action against you for breach of contract or
                    any other applicable grounds.
                  </li>
                </ul>
                <li>
                  If your access to the marketplace (or any part of it) is
                  suspended, blocked, or restricted, you are prohibited from
                  attempting to bypass these measures, including by creating or
                  using a new account.
                </li>
              </ul>
            </li>
            <li>
              <h4>Severability</h4>
              <ul>
                <li>
                  If any provision in these terms and conditions is deemed
                  unlawful or unenforceable by a court or other competent
                  authority, the remaining provisions shall remain valid and
                  enforceable.
                </li>
                <li>
                  If a provision is considered unlawful or unenforceable but
                  could become valid by removing a specific part, that portion
                  shall be deemed removed, and the rest of the provision shall
                  continue to apply.
                </li>
              </ul>
            </li>
            <li>
              <h4>Third-Party Rights</h4>
              <ul>
                <li>
                  These terms are intended solely for the benefit of the parties
                  involved (you and us) and do not grant rights or
                  enforceability to any third party.
                </li>
                <li>
                  The rights and obligations set forth in these terms may be
                  exercised by the parties involved without requiring consent
                  from any third party.
                </li>
              </ul>
            </li>
            <li>
              <h4>Governing Law and Jurisdiction</h4>
              <ul>
                <li>
                  These terms and conditions shall be interpreted and enforced
                  in accordance with the laws of the relevant jurisdiction.
                </li>
                <li>
                  Any disputes arising from these terms shall be exclusively
                  handled by the courts within the applicable jurisdiction.
                </li>
              </ul>
            </li>
          </ol>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Policies;
